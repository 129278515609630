@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --primary: 2, 63, 136; /* #023F88 */
    --secondary: 239, 57, 53; /* #EF3935 */
    --muted: 128, 128, 128; /* #808080 */
    --slate-50: 243, 251, 255; /* #F3FBFF */
    --slate-200: 228, 228, 228; /* #E4E4E4 */
    --slate-300: 231, 231, 231; /* #E7E7E7 */
    --default: 32, 32, 32; /* #202020  used for body font color */
  }

  @font-face {
    font-family: 'icomoon';
    src: url('../assets/fonts/icomoon.eot?exx2pa');
    src: url('../assets/fonts/icomoon.eot?exx2pa#iefix')
        format('embedded-opentype'),
      url('../assets/fonts/icomoon.ttf?exx2pa') format('truetype'),
      url('../assets/fonts/icomoon.woff?exx2pa') format('woff'),
      url('../assets/fonts/icomoon.svg?exx2pa#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }
}

@layer components {
  .btn {
    @apply text-base font-bold px-8 py-[15px] focus:outline-none whitespace-nowrap inline-flex items-center justify-center text-center transition-all leading-[1.05] rounded;
  }
  .btn-primary {
    @apply border-2 border-primary bg-primary text-white hover:bg-[#012e63] hover:border-[#012e63];
  }
  .btn-outline-primary {
    @apply border-2 border-primary text-primary hover:border-primary hover:bg-primary hover:text-white;
  }
  .btn-secondary {
    @apply border border-secondary bg-secondary text-white hover:bg-[#fe201c] hover:border-secondary;
  }
  .btn-outline-secondary {
    @apply border border-2 border-secondary bg-white text-secondary hover:border-secondary hover:bg-secondary hover:text-white font-bold;
  }
  .btn-disabled {
    @apply border-[#808080] bg-[#808080] text-white pointer-events-none;
  }
  .form-control {
    @apply bg-white font-medium border border-[#CFD6DD] text-sm block w-full px-4 py-3.5 leading-[1.458] focus:border-primary focus:outline-none placeholder-[#BABABA] rounded-md;
  }
  .form-control:disabled,
  .form-control.disabled {
    @apply bg-[#F4F7FA];
  }
  .container {
    max-width: 1232px !important; /* 1200 + 32 */
  }
  body {
    @apply rtl:text-right;
  }
}

input[type='password'] {
  font-family: 'icomoon';
  font-style: normal;
  speak: none;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: 4px;
  font-size: 8px;
  line-height: 2.55;
}

input[type='password']::placeholder {
  @apply font-mono tracking-normal text-sm;
}

.collapse-wrapper {
  display: grid;
  grid-template-rows: 0fr;
  transition: grid-template-rows 0.5s ease-out;
}

.collapse-wrapper.is-open {
  grid-template-rows: 1fr;
}

.loader .circle {
  background-image: conic-gradient(#dce6f0 75%, rgba(var(--primary), 1) 95%);
  animation: spin 1s infinite linear backwards;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (min-width: 768px) {
  .writing-mode-lr {
    writing-mode: vertical-lr;
  }
}

.no-scrollbar::-webkit-scrollbar {
  @apply hidden;
}

.no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.redux-toastr {
  position: absolute;
}

.textField__validation {
  color: red;
}

.redux-toastr .bottom-left {
  @apply w-[584px] max-w-full;
}

.redux-toastr .bottom-left > div {
  @apply px-2.5 py-2;
}

.redux-toastr .toastr {
  @apply opacity-100 min-h-[auto] shadow-none;
}

.redux-toastr .toastr.rrt-error {
  @apply bg-[#db6a64];
}

.redux-toastr .toastr.rrt-success {
  @apply bg-[#6fcf97];
}

.redux-toastr .toastr:hover:not(.rrt-message) {
  @apply shadow-none;
}

.redux-toastr .toastr > div {
  @apply flex;
}

.redux-toastr .toastr .rrt-left-container,
.redux-toastr .toastr .rrt-title {
  @apply hidden;
}

.redux-toastr .toastr .rrt-text {
  @apply text-white text-sm font-sans;
}

.redux-toastr .toastr .rrt-middle-container {
  @apply m-0 w-full px-[18px] py-4;
}

.redux-toastr .toastr .close-toastr {
  @apply relative opacity-100 text-white w-auto text-[15px] font-bold p-0;
}

.cms-block h3 {
  @apply pt-6 pb-3;
}

.cms-block ul li {
  @apply flex mb-2 before:w-1.5 before:h-1.5 before:min-w-[6px] before:bg-black before:rounded-full before:ltr:mr-3 before:rtl:ml-3 before:mt-2;
}

.cms-block ul ul {
  @apply ltr:pl-4 rtl:pr-4;
}
